html, body, #root {
  height: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.text-muted {
  color: #767676;
}

.centered {
  text-align: center;
}
