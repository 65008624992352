.goals input[name="name"] {
  font-style: italic;
  margin-left: 36px !important;
}

.header.medium {
  font-weight: lighter !important;
}

.checked label {
  text-decoration: line-through;
  color: gray !important;
}

.ui.checkbox label {
  font-weight: bold;
}
