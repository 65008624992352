.ui.horizontal.label.min-width-label {
  min-width: 90px;
}

.flex {
  display: flex;
}

.flex-none {
  flex: 0 0 auto;
}

.flex-stretch {
  flex: 1 1 auto;
}

.practice-content {
  margin-left: 20px;
}

.description {
  color: hsla(0,0%,0%,0.78);
  margin: 0;
}

.meta {
  color: hsla(0,0%,0%,0.78);
}
