.ui.inverted.list .item a:not(.ui).footer-link {
  color: rgba(255, 255, 255,0.56) !important;
}

.ui.inverted.list .item a:not(.ui).footer-link:hover {
  color: rgba(255, 255, 255,0.78) !important;
  text-decoration: underline;
}

.copyright {
  color: rgba(255, 255, 255,0.23);
}
